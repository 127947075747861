import { useSelector } from 'Hooks';
import { CURRENCY } from 'Types'; // Import should be from globalTypes file but react does not allow imports from outside of /src **TODO** Find a way to import globalTypes

const currencyToLocaleMap: Record<CURRENCY, string> = {
  [CURRENCY.DKK]: 'da-DK',
  [CURRENCY.EUR]: 'nl-NL',
  [CURRENCY.NOK]: 'nb-NO',
  [CURRENCY.USD]: 'en-US',
  [CURRENCY.SEK]: 'sv-SE',
  [CURRENCY.GBP]: 'en-GB',
};

export const useCurrency = () => {
  const currency = useSelector((state) => state.companyCurrency);
  const formatAmount = (amount: number) => {
    // Guard, if the currency type is misstyped/is not supported
    if (!CURRENCY[currency]) {
      return (amount / 100).toString();
    }
    const formatter = new Intl.NumberFormat(currencyToLocaleMap[currency], {
      style: 'currency',
      currency: CURRENCY[currency],
      minimumFractionDigits: 0,
      maximumFractionDigits: 3,
    });

    return formatter.format(amount / 100);
  };

  return {
    formatAmount,
  };
};
