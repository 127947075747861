import { Route, Switch, useLocation } from 'react-router-dom';
import useI18n from 'i18n';

import LandingPage from 'Scenes/LandingPage';
import HistoryListing from 'Scenes/HistoryListing';
import HistoryOrder from 'Scenes/HistoryOrder';
import Membership from 'Scenes/Membership';
import NewsListing from 'Scenes/NewsListing';
import SidebarContent from 'Scenes/SidebarContent';
import Support from 'Scenes/Support';
import CookieSettings from 'Scenes/CookieSettings';
import StoreListing from 'Scenes/StoreListing';
import NewsArticle from 'Scenes/NewsArticle';
import Stack from './Stack';
import Home from 'Scenes/Home';
import Menu from 'Scenes/Menu';
import Cart from 'Scenes/Cart';
import StartPage from 'Scenes/StartPage';
import MenuCategories from 'Scenes/MenuCategories';
import Item from 'Scenes/Item';
import TableLandingPage from 'Scenes/TableLandingPage';
import PrivacyPolicy from 'Scenes/PrivacyPolicy';
import InvoiceSignup from 'Scenes/InvoiceSignup';
import { useConfig } from 'Components/ConfigProvider';
import MarketplaceLandingPage from 'Scenes/MarketplaceLandingPage';
import { CartChecker } from './CartManager';

const Routes = () => {
  const config = useConfig();
  const { i18n } = useI18n();
  const location = useLocation();

  const params = new URLSearchParams(window.location.search);

  return (
    <>
      <Switch>
        <Route path="/order/:venue_id">
          {/* level 999 to prevent transform -10%  */}
          <Stack level={999} hideNav noSwipe>
            <StartPage path="/order/:venue_id">
              {/*@ts-ignore */}
              <Route path="/order/:venue_id/cart" component={Cart} withFooterTabs />
              {/*@ts-ignore */}
              <Route path="/order/:venue_id/menu" component={MenuCategories} withFooterTabs />
              {/*@ts-ignore */}
              <Route exact path="/order/:venue_id" component={Home} withFooterTabs />

              {/*@ts-ignore */}
              <Route path="/order/:venue_id/item/:item_id" component={Home} withFooterTabs />
            </StartPage>
          </Stack>

          <Route
            path="/order/:venue_id/menu/:id"
            children={
              <Stack
                navShowOnScroll={50}
                slideIn
                level={2}
                showFooterTabs
                scrollClass={'category-scroll'}>
                <Menu />
              </Stack>
            }
          />

          <Route
            path="/order/:venue_id/store-listing"
            children={
              <Stack
                navTitle={i18n('StoreList.Title')}
                backgroundColor="#eeeef1"
                slideIn
                level={2}>
                <StoreListing />
              </Stack>
            }
          />
          <Route
            path="/order/:venue_id/menu/:id/item/:item_id"
            children={
              <Stack slideIn level={1}>
                <Item />
              </Stack>
            }
          />
          <Route
            path="/order/:venue_id/item/:item_id"
            children={
              <Stack slideIn level={2}>
                <Item />
              </Stack>
            }
          />
          <Route
            path="/order/:venue_id/order/:id"
            children={
              <Stack navTitle={i18n('OrderHistory.Title')} slideIn level={2}>
                <HistoryOrder tableId={params.get('t') ? params.get('t') : ''} />
              </Stack>
            }
          />
        </Route>

        <Route
          path="/"
          children={
            <Stack
              navShowOnScroll={50}
              hideNav={Boolean(params.get('v'))}
              showLogo
              showMenuIcon
              isBackground={location.pathname !== '/'}
              level={1}
              noSwipe>
              {(() => {
                if (config.MARKETPLACE_ID) {
                  return <MarketplaceLandingPage />;
                }

                if (params.get('v')) {
                  return <TableLandingPage tableId={params.get('t')} venueId={params.get('v')!} />;
                }

                return <LandingPage />;
              })()}
            </Stack>
          }
        />
      </Switch>

      {/* Render the Cart Checker component on all routes with /order in them.
          This is to ensure that the cart is checked on all routes that are
          related to the order process.
      */}
      <Route path="/order" children={<CartChecker />} />

      <Route
        path="/news"
        children={
          <Stack
            navTitle={i18n('News.Title')}
            backToHome
            isBackground={location.pathname !== '/news'}
            level={2}>
            <NewsListing />
          </Stack>
        }
      />

      <Route
        path="/news/:id"
        children={
          <Stack navTitle={i18n('News.Title')} navShowOnScroll={1} slideIn level={3}>
            <NewsArticle />
          </Stack>
        }
      />

      <Route
        path="/latestnews/:id"
        children={
          <Stack navTitle={i18n('News.Title')} navShowOnScroll={1} slideIn level={2}>
            <NewsArticle />
          </Stack>
        }
      />

      <Route
        path="/support"
        children={
          <Stack navTitle={i18n('Support.Nav.Title')} backToHome level={2}>
            <Support />
          </Stack>
        }
      />

      <Route
        path="/cookies"
        children={
          <Stack navTitle={i18n('CookieSettings.Nav.Title')} backToHome level={2}>
            <CookieSettings />
          </Stack>
        }
      />

      <Route
        path="/sidebar/:slug"
        children={
          <Stack useSlugName backToHome level={2}>
            <SidebarContent />
          </Stack>
        }
      />

      {/* <Route
        path="/activate-code"
        children={
          <Stack navTitle={i18n('ActivateCode.Title')} backToHome>
            <ActivateCode />
          </Stack>
        }
      /> */}

      <Route
        path="/membership"
        children={
          <Stack navTitle={i18n('Membership.Title')} backToHome level={2}>
            <Membership />
          </Stack>
        }
      />

      <Route
        path="/store-listing"
        children={
          <Stack
            navTitle={i18n('StoreList.Title')}
            backgroundColor="#eeeef1"
            backToHome
            slideIn
            level={2}>
            <StoreListing />
          </Stack>
        }
      />

      <Route
        path="/privacy-policy"
        children={
          <Stack navTitle="Privacy Policy" backToHome level={2}>
            <PrivacyPolicy />
          </Stack>
        }
      />

      <Route
        path="/invoice-signup"
        children={
          <Stack
            navTitle={i18n('InvoiceSignup.Title')}
            backgroundColor="#f8f8fb"
            backToHome
            isBackground={location.pathname !== '/invoice-signup'}
            level={2}>
            <InvoiceSignup />
          </Stack>
        }
      />

      <Route
        path="/order-history"
        children={
          <Stack
            navTitle={i18n('OrderHistory.Title')}
            backgroundColor="#f8f8fb"
            backToHome
            isBackground={location.pathname !== '/order-history'}
            level={2}>
            <HistoryListing />
          </Stack>
        }
      />
      <Route
        path="/order-history/:id"
        children={
          <Stack navTitle={i18n('OrderHistory.Title')} slideIn level={3}>
            <HistoryOrder tableId={null} />
          </Stack>
        }
      />

      <Route
        path="/orderstatus/:id"
        children={
          <Stack navTitle={i18n('OrderHistory.Title')} slideIn level={2}>
            <HistoryOrder tableId={null} />
          </Stack>
        }
      />
    </>
  );
};

export default Routes;
