import { Item } from '@baemingo/inventory-events/dist/types';
import { InventoryState } from 'actions';

/**
 *
 * @param itemId Id of the item to check for stock
 * @param inventory Inventory state
 * @param disregardCount Whether to take the count of the item into account
 * @returns boolean
 */
export const checkOutOfStock = (
  itemId: string,
  inventory: InventoryState,
  disregardCount = false,
) => {
  const inventoryForItem = inventory.find((invItem: Item) => invItem.itemId === itemId);
  if (disregardCount) {
    return inventoryForItem ? inventoryForItem.outOfStock : false;
  }
  return !!(inventoryForItem?.outOfStock || inventoryForItem?.count === 0);
};

export const checkVariationOutOfStock = (variationId: string, inventory: InventoryState) => {
  const inventoryForItem = inventory.find((invItem: Item) => invItem.itemId === variationId);
  return inventoryForItem ? inventoryForItem.outOfStock : false;
};
