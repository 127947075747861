import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
import { datadogLogs } from '@datadog/browser-logs';
import { Provider } from 'react-redux';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink,
} from '@apollo/client';
import Config from 'config';
import { onError } from '@apollo/client/link/error';

import App from 'Components/App';
import { ShoppingCartUniverseProvider } from 'Components/ShoppingCartUniverse';
import { StorageProvider } from 'Components/Storage';
import ThemeProviderWrapper from 'Components/ThemeProvider';
import SocketProvider from 'Components/SocketManager';
import ConfigProvider from 'Components/ConfigProvider';
import { nanoid } from 'nanoid';
import store from './store';
import './index.css';
import './Assets/Fonts/font-face.css';

declare global {
  interface Window {
    hasNativeWrapper: boolean;
    hasSmallViewport: boolean;
    headerHeight: number;
    __DEV__?: boolean;
    sidebarWidth: number;
    ReactNativeWebView: any;
    safeAreaInset: number;
    webkit: any;
  }
}

// Determine if we're using native wrapper or in the browser.
let nativeWrapper: boolean;
const userAgent = window.navigator.userAgent.toLowerCase();
const safari = /safari/.test(userAgent);
const ios = /iphone|ipod|ipad/.test(userAgent);

if (ios) {
  if (safari) {
    nativeWrapper = false;
  } else if (!safari) {
    nativeWrapper = true;
  }
} else {
  nativeWrapper = Boolean(window.ReactNativeWebView);
}

// @ts-ignore
window.hasNativeWrapper = nativeWrapper;
window.hasSmallViewport = window.innerHeight <= 667;

// This value is used in a lot of places, so lets have one place where we set it.
window.headerHeight = window.hasNativeWrapper && !window.hasSmallViewport ? 96 : 68;

window.sidebarWidth = 303;
window.__DEV__ = Boolean(process.env.NODE_ENV === 'development');

const root = document.documentElement;

export const onResize = () => {
  // If window innerHeight is less than 500px, we get very little space to have content on.
  // Therefor, we don't change the height of the root, when it's less than 500.
  root.style.setProperty('--height', (window.innerHeight > 500 ? window.innerHeight : 500) + 'px');
};

setInterval(onResize, 200);

const API_URL = Config.API_URL;

/**
 * Send console logs to datadog (monitoring).
 * We init even when __DEV__ is true but only send to API when not
 */
datadogLogs.init({
  // API token
  clientToken: Config.DATADOG_CLIENT_TOKEN!,

  // Automatically log all errors
  forwardErrorsToLogs: !__DEV__,

  // Reduce this if the log volume is too high
  sampleRate: 100,

  // Tag the logs with the current environment
  env: Config.IS_PRODUCTION ? 'production' : 'development',

  // Avoid DD to be spammed with XHR "AbortedError" logs.
  beforeSend: (log) => {
    if (
      log.error &&
      log.error.origin === 'network' &&
      log.message.includes('Fetch error POST') &&
      log.http.status_code === 0
    ) {
      return false;
    }
    return true;
  },
});

datadogLogs.addLoggerGlobalContext('origin', 'app');

datadogLogs.addLoggerGlobalContext('company', Config.DISPLAY_NAME.toLowerCase());

const ProvidersWrapper = () => {
  const [token, setToken] = useState<string | null>(null);

  const client = useMemo(() => {
    const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
      if (graphQLErrors && window.__DEV__) {
        graphQLErrors.slice(0, 10).forEach(({ message, locations, path }) => {
          console.warn(
            `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
              locations,
            )}, Path: ${path}, Operation`,
            operation,
          );
        });
      }

      if (networkError) {
        console.error(`[Network error]: ${networkError}, Operation`, operation);
        const offline = store.getState().offline;
        if (!offline) {
          store.dispatch({
            type: 'SET_CONNECTION_STATUS',
            offline: true,
          });
        }
      }
    });
    const AuthLink = new ApolloLink((operation, forward) => {
      operation.setContext((context: any) => ({
        ...context,
        headers: {
          ...context.headers,
          authorization: `Bearer ${token}`,
        },
      }));

      return forward(operation);
    });

    /**
     * Add trace id to all query headers.
     * Used to connect error logs.
     */
    const traceId = nanoid(12);
    const TraceIDLink = new ApolloLink((operation, forward) => {
      operation.setContext((context: any) => ({
        ...context,
        headers: {
          ...context.headers,
          'x-baemingo-trace-id': traceId,
        },
      }));

      return forward(operation);
    });

    /**
     * Adds the name of the query/mutation to the URL for easier
     * debugging in the network log. Only active in local dev
     */
    const addOpNameToUrl = (uri: string, options: any) => {
      const { operationName } = JSON.parse(options.body);
      return fetch(`${uri}graphql?q=${operationName}`, options);
    };
    const link = ApolloLink.from([
      errorLink,
      AuthLink,
      TraceIDLink,
      createHttpLink({
        uri: API_URL,
        fetch: __DEV__ ? addOpNameToUrl : undefined,
      }),
    ]);

    const cache = new InMemoryCache({
      // @ts-ignore
      dataIdFromObject: (obj) => obj.id,
      typePolicies: {
        Venue: {
          fields: {
            inventory: {
              merge(existing, incoming) {
                // Better, but not quite correct.
                return { ...existing, ...incoming };
              },
            },
          },
        },
      },
    });

    return new ApolloClient({
      link,
      name: 'Platform',
      cache,
      assumeImmutableResults: true,
    });
  }, [token]);

  return (
    <ApolloProvider client={client}>
      <ShoppingCartUniverseProvider>
        <ConfigProvider>
          <StorageProvider
            onChange={async (data) => {
              // The token is used in the apollo client - this is a weird setup due to
              // circular dependencies between providers
              if (data.authToken !== token || data.temporaryAuthToken !== token) {
                setToken(data.authToken || data.temporaryAuthToken);
              }
            }}>
            <ThemeProviderWrapper>
              <SocketProvider>
                <Router>
                  <App />
                </Router>
              </SocketProvider>
            </ThemeProviderWrapper>
          </StorageProvider>
        </ConfigProvider>
      </ShoppingCartUniverseProvider>
    </ApolloProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ProvidersWrapper />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
