import React from 'react';
import { ApolloError } from '@apollo/client/errors';
import Item from './Scenes/Item';
import Upsales from './Scenes/Upsales';
import { SCENES, UpsalesQuantity } from './';
import { ALLERGEN } from '../../../__queries__';
import { GetItem_item_upsales } from './__queries__';
import { VariationGroup } from '../../Components/Variations';

type Props = {
  activeScene: string;
  itemImage: string | undefined;
  itemTitle: string;
  itemDescription: string;
  itemInventoryCount: number | null;
  allRequiredVariationsSelected: boolean;
  isLoggedIn: boolean;
  loading: boolean;
  allergens: ALLERGEN[];
  variationGroups: VariationGroup[];
  onSelectVariationId: (id: string) => void;
  onItemButton: () => void;
  total: number;
  quantity: string;
  onChangeQuantity: (quantity: string) => void;
  error: ApolloError | undefined;
  showLoginModal: boolean;
  upsalesStep: number;
  setUpsalesStep: React.Dispatch<React.SetStateAction<number>>;
  onUpsalesButton: () => void;
  upsales: GetItem_item_upsales[];
  upsalesQuantity: UpsalesQuantity[];
  onChangeUpsalesQuantity: (groupId: string, id: string, quantity: string) => void;
};

const View = ({
  activeScene,
  itemImage,
  itemTitle,
  itemDescription,
  itemInventoryCount,
  allRequiredVariationsSelected,
  isLoggedIn,
  loading,
  onItemButton,
  allergens,
  variationGroups,
  onSelectVariationId,
  total,
  quantity,
  onChangeQuantity,
  error,
  showLoginModal,
  upsalesStep,
  setUpsalesStep,
  onUpsalesButton,
  upsales,
  upsalesQuantity,
  onChangeUpsalesQuantity,
}: Props) => {
  if (activeScene === SCENES.ITEM) {
    return (
      <Item
        allergens={allergens}
        variationGroups={variationGroups}
        itemImage={itemImage}
        itemTitle={itemTitle}
        itemDescription={itemDescription}
        itemInventoryCount={itemInventoryCount}
        allRequiredVariationsSelected={allRequiredVariationsSelected}
        onSelectVariationId={onSelectVariationId}
        onItemButton={onItemButton}
        total={total}
        quantity={quantity}
        onChangeQuantity={onChangeQuantity}
        showLoginModal={showLoginModal}
        error={error}
        isLoggedIn={isLoggedIn}
        loading={loading}
      />
    );
  }

  if (activeScene === SCENES.UPSALES) {
    return (
      <Upsales
        groups={upsales}
        upsalesQuantity={upsalesQuantity}
        step={upsalesStep}
        setStep={setUpsalesStep}
        onSubmit={onUpsalesButton}
        onChangeQuantity={onChangeUpsalesQuantity}
      />
    );
  }
  return null;
};

export default View;
