import React, { useState, useMemo, useEffect } from 'react';
import difference from 'lodash/difference';
import flatMap from 'lodash/flatMap';
import gql from 'graphql-tag';
import { useApolloClient } from '@apollo/client';

import { useSelector } from '../../Hooks';
import { checkVariationOutOfStock } from '../../utils/inventory';
import useI18n from '../../i18n';
import { AppliedVariationFragment, DefaultVariationFragment } from './__queries__';

export interface LocalVariation {
  id: string;
  name: string;
  price: number;
}

export enum VARIATION_TYPE {
  MULTIPLE = 'MULTIPLE',
  SINGLE = 'SINGLE',
}

interface Variation {
  id: string;
  name: string;
  selected: boolean;
  price: number;
  imageUrl: string | undefined;
  isDefault: boolean;
  isOutOfStock?: boolean;
}

export interface VariationGroup {
  id: string;
  title: string;
  type: VARIATION_TYPE;
  variations: Variation[];
  required: boolean;
  // True if this group has a valid selection or is not required
  valid: boolean;
  expandedByDefault: boolean;
}

type VariationHelpers = [
  VariationGroup[],
  (id: string) => void,
  LocalVariation[],
  LocalVariation[],
];

interface Props {
  appliedVariations: string[];
  defaultVariations: string[];
  /* Array of IDs for variations which have already been selected */
  initialSelection?: string[];
}

export const useVariations = ({
  appliedVariations: avIDs,
  defaultVariations: defIDs,
  initialSelection,
}: Props): VariationHelpers => {
  const client = useApolloClient();
  const { i18n } = useI18n();
  const inventory = useSelector((state) => state.inventory);

  let [appliedVariations, defaultVariations]: [
    AppliedVariationFragment[],
    DefaultVariationFragment[],
  ] = useMemo(() => {
    const defaults = defIDs.map(
      (id) =>
        client.readFragment<DefaultVariationFragment>({
          id,
          fragment: gql`
            fragment DefaultVariationFragment on Variation {
              id
              price
              name
              nameLang {
                id
                se
                en
                es
              }

              # This is not actually used, but makes both variation
              # fragments have the same schema which makes life easier
              asItem {
                id
              }

              image {
                id
                file {
                  id
                  url
                }
              }
            }
          `,
        })!,
    );

    const applied = avIDs.map(
      (id) =>
        client.readFragment<AppliedVariationFragment>({
          id,
          fragment: gql`
            fragment AppliedVariationFragment on AppliedVariation {
              id
              required
              expandedByDefault

              group {
                id
                type
                required

                title {
                  id
                  se
                  en
                  es
                }
                preselectedVariations {
                  id
                }
                variations {
                  id
                  price
                  name
                  asItem {
                    id
                  }

                  nameLang {
                    id
                    se
                    en
                    es
                  }

                  image {
                    id
                    file {
                      id
                      url
                    }
                  }
                }
              }
            }
          `,
        })!,
    );

    return [applied, defaults];
  }, [avIDs, defIDs]);

  // If there is no data in the cache
  if (appliedVariations[0] === null) {
    appliedVariations = [];
    defaultVariations = [];
  }

  // Selected variations are all variations that are not equal to their inital state.
  // We dont differentiate between normal and default variations here
  const [selectedVariations, setSelectedVariations] = useState<string[]>(initialSelection || []);
  const [preselectedVariationsAssigned, setPreselectedVariationsAssigned] = useState<boolean>(
    false,
  );

  // Update the selected variations when the initial selection changes
  useEffect(() => {
    // If the initial selection is not present in the selected variations
    // we add it to the selected variations
    if (initialSelection && selectedVariations.every((sv) => !initialSelection.includes(sv))) {
      setSelectedVariations((prev) => [...prev, ...initialSelection]);
    }
  }, [initialSelection?.length]);

  // Create an array of all variations
  const variations = flatMap(appliedVariations.map((av) => av.group.variations)).concat(
    defaultVariations,
  );

  // Create an array of all groups
  const groups = flatMap(appliedVariations.map((av) => av.group));

  useEffect(() => {
    if (groups.length === 0 || preselectedVariationsAssigned) {
      return;
    }
    const preselectedVariations = flatMap(groups.map((g) => g.preselectedVariations));
    const ids = preselectedVariations.map((v) => v.id);
    setPreselectedVariationsAssigned(true);
    setSelectedVariations((selectedVariations) => [...selectedVariations, ...ids]);
  }, [groups]);

  // Helper functions
  const isDefault = (id: string) => defaultVariations.some((dv) => dv.id === id);
  const isSelected = (id: string) => selectedVariations.includes(id);

  // Click handler when selecting a variation
  const onSelectVariation = (id: string) => {
    const selected = isSelected(id);
    const group = groups.find((group) => group.variations.some((vari) => vari.id === id))!;

    if (selected) {
      if (group.type === VARIATION_TYPE.SINGLE) {
        // If it's a single group and we are clicking an option
        // that was already selected, do nothing.
        return;
      }

      // Remove the variation from our selection
      setSelectedVariations(selectedVariations.filter((svID) => svID !== id));
    } else {
      if (group.type === VARIATION_TYPE.SINGLE) {
        // Find all variation IDs of the group we are modifying
        const idsInGroup = group.variations.map((vari) => vari.id);

        // Remove any previous selection in this group
        const selectionsWithoutGroupNeighbours = selectedVariations.filter(
          (id) => !idsInGroup.includes(id),
        );

        // Set our single selection
        setSelectedVariations(Array.from(new Set([...selectionsWithoutGroupNeighbours, id])));
      } else {
        // Add selection to group
        setSelectedVariations(Array.from(new Set([...selectedVariations, id])));
      }
    }
  };

  // Find all removed variations and convert them to local (i18n) format
  const removedVariations: LocalVariation[] = useMemo(
    () =>
      defaultVariations
        .filter((defVari) => selectedVariations.some((id) => id === defVari.id))
        .map((rv) => ({
          ...rv,
          name: i18n(rv.nameLang),
        })),
    [selectedVariations],
  );

  // Find all selected variations, excluding default variations
  const formattedSelectedVariations = difference(selectedVariations, defIDs).map((id) => {
    const variation = variations.find((v) => v.id === id);

    if (variation) {
      return {
        id: variation.id,
        name: i18n(variation.nameLang),
        price: variation.price,
      };
    } else
      return {
        id: '',
        name: '',
        price: 0,
      };
  });

  // Format our state to something that is easily consumable by the client
  const formattedGroups: VariationGroup[] = useMemo(
    () =>
      appliedVariations
        .map(
          (av): VariationGroup => {
            let atLeastOneSelected = false;
            const preselectedVariationsExist = av.group.preselectedVariations.length > 0;
            const variations = av.group.variations.map((vari) => {
              const selected = isSelected(vari.id);
              const isDefaultVariation = isDefault(vari.id);

              // Inverse selection set for default variations.
              // A selected default variation is actually an un-selection
              const markAsSelected = isDefaultVariation ? !selected : selected;

              atLeastOneSelected = markAsSelected || atLeastOneSelected;

              // Check if the variation is present in the inventory universe,
              // and if it is, add the inventory metadata to the variation
              const isOutOfStock = checkVariationOutOfStock(vari.asItem?.id || vari.id, inventory);
              const variName = i18n(vari.nameLang);
              return {
                id: vari.id,
                name: variName.length > 0 ? variName : vari.name,
                price: vari.price,
                selected: markAsSelected,
                imageUrl: vari.image?.file.url,
                isDefault: isDefaultVariation,
                isOutOfStock,
              };
            });
            return {
              id: av.id,
              title: i18n(av.group.title),
              variations,
              type: av.group.type,
              expandedByDefault: av.expandedByDefault || preselectedVariationsExist,
              required: av.required,
              valid: !av.required || atLeastOneSelected,
            };
          },
        )
        // Sort required first
        .sort((a, b) => +b.required - +a.required),
    [appliedVariations, selectedVariations],
  );

  return [formattedGroups, onSelectVariation, formattedSelectedVariations, removedVariations];
};
