import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import useI18n from '../../../i18n';

const Wrapper = styled.div<{ small?: boolean }>`
  display: flex;
  width: ${({ small }) => (small ? 'auto' : '136px')};
  padding: ${({ small }) => (small ? '0 0 0 10px' : '24px 16px 0 16px')};
  justify-content: space-between;
  background: #ffffff;
`;

const Count = styled.input<{ small?: boolean }>`
  height: 32px;
  width: 32px;
  font-size: ${({ small }) => (small ? '12px' : '16px')};
  font-weight: 600;
  color: #212124;
  border: none;
  text-align: center;
  padding: 0;
  outline: none;
  user-select: text;
  background: transparent;
`;

const Button = styled.div<{ small?: boolean }>`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: ${({ small }) => (small ? 'solid 1px #c1c1c1' : 'none')};
`;

const MinusIcon = styled.span<{ small?: boolean }>`
  background: #000;
  height: 1.5px;
  width: ${({ small }) => (small ? '10px' : '17.5px')};
`;

const PlusIcon = styled.span<{ small?: boolean }>`
  position: relative;
  width: ${({ small }) => (small ? '10px' : '17.5px')};
  height: ${({ small }) => (small ? '10px' : '17.5px')};

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: #212124;
  }

  /* Vertical line */
  &:before {
    top: 0;
    left: 50%;
    width: 1.5px;
    height: 100%;
    margin-left: -0.75px;
  }

  /* horizontal line */
  &:after {
    top: 50%;
    left: 0;
    width: 100%;
    height: 1.5px;
    margin-top: -0.75px;
  }
`;

type Props = {
  /**
   * setQuantity
   */
  setQuantity: (quantity: string) => void;
  /**
   * quantity
   */
  quantity: string;
  /**
   * size
   */
  small?: boolean;
  /**
   * minValue
   */
  minValue: number;
  /**
   * maxValue
   */
  maxValue?: number | null;
  /**
   * disabled
   */
  disabled?: boolean;
};

/**
 * Quantity
 */
const Quantity = ({ setQuantity, quantity, small, minValue, maxValue, disabled }: Props) => {
  const { i18n } = useI18n();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | null, action?: number) => {
    if (disabled) {
      return;
    }

    const value = event?.target?.value || action;

    if (!value) {
      return;
    }

    const quantityInt: number = parseInt(quantity, 10);
    let newValue;

    if (typeof value === 'string') {
      newValue = value.replace(/\D/g, '');
      if (newValue[0] === '0') {
        newValue = newValue.substr(1);
      }
    } else {
      newValue = (quantityInt + value).toString();
    }

    const newValueInt = parseInt(newValue);

    if (newValueInt < minValue) {
      newValue = minValue.toString();
    }

    if (maxValue && newValueInt > maxValue) {
      newValue = maxValue.toString();

      toast(i18n('General.LimitStock'), {
        position: 'bottom-center',
        autoClose: 300,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    }

    if (quantity !== newValue) {
      setQuantity(newValue);
    }
  };

  const onBlur = () => {
    if (quantity === '') {
      setQuantity(minValue.toString());
    }
  };

  return (
    <Wrapper small={small}>
      <Button onClick={() => handleChange(null, -1)} small={small}>
        <MinusIcon small={small} />
      </Button>
      <Count
        type="text"
        onChange={handleChange}
        value={quantity.toString()}
        small={small}
        onBlur={onBlur}
      />
      <Button onClick={() => handleChange(null, 1)} small={small}>
        <PlusIcon small={small} />
      </Button>
    </Wrapper>
  );
};

export default Quantity;
