import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { gql, useMutation } from '@apollo/client';

import useI18n from 'i18n';
import * as actions from 'actions';
import { useBridgeApi, useSelector, useDispatch, useLogger } from 'Hooks';
import { useShoppingCart, useShoppingCartApi } from 'Components/ShoppingCartUniverse';
import { useStorage } from 'Components/Storage';
import find from 'lodash/find';
import matches from 'lodash/matches';
import isString from 'lodash/isString';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import ReactGA from 'react-ga4';
import { getBoundariesFromAvailability, isNight } from 'utils/venueOpeningHours';
import View from './view';
import resultCodeSwitch from './utils/resultCodeSwitch';
import { InitSession, UpdateAdyenPaymentDetails } from './__queries__';
import { checkOutOfStock, checkVariationOutOfStock } from 'utils/inventory';
import { useConfig } from 'Components/ConfigProvider';

dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);

export const INIT_MUTATION = gql`
  mutation InitSession(
    $venueId: String!
    $amount: Int!
    $reference: String!
    $returnUrl: String!
    $companyId: String!
    $tip: Int
    $currency: String
    $force3DS: Boolean
  ) {
    initAdyenSession(
      venueId: $venueId
      amount: $amount
      reference: $reference
      returnUrl: $returnUrl
      companyId: $companyId
      tip: $tip
      currency: $currency
      force3DS: $force3DS
    ) {
      success
      response
      error
    }
  }
`;

const UPDATE_DETAILS_MUTATION = gql`
  mutation UpdateAdyenPaymentDetails($venueId: ID!, $payload: Json!) {
    updateAdyenPaymentDetails(venueId: $venueId, payload: $payload) {
      success
      response
      error
    }
  }
`;

export const ACTIVATE_PROMOCODE = gql`
  mutation ActivatePromoCode(
    $companyId: String!
    $code: String!
    $cartId: String!
    $venueId: String!
  ) {
    activatePromoCode(companyId: $companyId, code: $code, cartId: $cartId, venueId: $venueId) {
      success
      promoCode {
        id
        code
        active
        discountType
        discountRate
        title {
          en
          se
          es
        }
        venues
        categoryIds
      }
      error
    }
  }
`;

export enum ModalType {
  Hide = '',
  DesiredTime = 'DesiredTime',
  Error = 'Error',
  Comment = 'Comment',
  Discount = 'Discount',
  PaymentError = 'PaymentError',
  PaymentRefused = 'PaymentRefused',
  AdyenDropIn = 'AdyenDropIn',
  PaymentComplete = 'PaymentComplete',
  SessionError = 'SessionError',
  AfterPaymentError = 'AfterPaymentError',
  PayLaterOrderPending = 'PayLaterOrderPending',
  PayLaterOrderComplete = 'PayLaterOrderComplete',
}

const Cart = () => {
  const config = useConfig();
  const logger = useLogger('checkout');
  const { search } = useLocation();
  const [redirectResult, setRedirectResult] = useState(
    new URLSearchParams(search).get('redirectResult'),
  );
  const [swishParams, setSwishParams] = useState({
    sessionId: new URLSearchParams(search).get('sessionId'),
    pp: new URLSearchParams(search).get('pp'),
  });
  const params = useParams<{ venue_id?: string }>();
  const venueId = params?.venue_id || '';
  const dispatch = useDispatch();
  const shoppingCartApi = useShoppingCartApi();
  const { i18n } = useI18n();
  const { push } = useHistory();
  const [storage, updateStorage, storageLoading] = useStorage();
  const {
    cartErrorAmount,
    cartId,
    venue,
    tableId,
    user,
    companyCurrency,
    qrOrder,
    inventory,
    minimumPickupTime,
  } = useSelector((state) => state);
  const shoppingCartErrors = useSelector((state) => state.shoppingCartEvents).filter(
    (event) => event.type === 'ERROR',
  ).length;
  const api = useBridgeApi();
  const shoppingCart = useShoppingCart();
  const [time, setTime] = useState('ASAP');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [sessionData, setSessionData] = useState<object | null>(null);
  const [showModal, setShowModal] = useState<ModalType>(ModalType.Hide);
  const [payload, setPayload] = useState('');
  const [tips, setTips] = useState(0);

  // Keep track of the most recent init session call
  const initSessionRef = useRef(0);

  const minimumPickupWaitTime = config.SYNC_ETA ? minimumPickupTime : 15;

  const [initSession, session] = useMutation<InitSession>(INIT_MUTATION, {
    variables: {
      venueId,
      amount: shoppingCart.total,
      reference: cartId,
      returnUrl: `${window.location.href}?cart=${cartId}&type=${shoppingCart.serviceType}`,
      companyId: config.COMPANY_ID,
      tip: tips,
      currency: companyCurrency ?? 'SEK',
      // Enforce 3DS in checkout depending on config
      force3DS: config.FORCE_3DS,
    },
  });

  const [updatePaymentDetails] = useMutation<UpdateAdyenPaymentDetails>(UPDATE_DETAILS_MUTATION, {
    variables: {
      venueId,
      payload,
    },
  });

  const venueOpen = useMemo(() => {
    if (!venue) {
      return { loading: true, open: false };
    }

    const getDayOfWeek = () => {
      const hours = selectedTime.hour();
      const selectedTimeIsNight = isNight(hours);
      // if night - subtract 1 day to check not the next day hours
      const day = selectedTimeIsNight ? selectedTime.subtract(1, 'day') : selectedTime;
      return day.format('dddd').toUpperCase();
    };

    const selectedTime = time === 'ASAP' ? dayjs() : dayjs(time);

    const dayOfWeek = getDayOfWeek();

    const availabilities =
      venue && venue.availabilities.length > 0
        ? find(
            venue.availabilities,
            matches({
              dayOfWeek,
            }),
          )
        : null;

    const availability = getBoundariesFromAvailability(availabilities, time);

    if (isString(availability)) {
      return { loading: false, open: false };
    }

    const from = availability.from.second(0).millisecond(0);
    const to = availability.to.second(0).millisecond(0);

    if (selectedTime.isBetween(from, to)) {
      return {
        loading: false,
        open: true,
        to: to.toISOString(),
      };
    } else {
      return {
        loading: false,
        open: false,
        to: to.toISOString(),
      };
    }
  }, [venue, time]);

  const checkAndUpdatePickUpTime = useCallback(() => {
    const now = dayjs();
    const to = dayjs(venueOpen.to);

    const selectedTime = dayjs(shoppingCart.pickupTime);

    if (selectedTime.isBefore(to)) {
      const diff = selectedTime.diff(now, 'minute', true);

      if (diff < minimumPickupWaitTime - 1) {
        shoppingCartApi.setPickupTime('ASAP');
      }

      return true;
    } else {
      shoppingCartApi.setPickupTime('ASAP');
      return false;
    }
  }, [venueOpen, shoppingCart.pickupTime, minimumPickupWaitTime]);

  const checkCartItemAvailability = () => {
    let itemOutOfStock = false;

    // Loop through every item in the cart to check if any of them has been set to out of stock
    shoppingCart.items.forEach((item) => {
      const outOfStock = checkOutOfStock(item.id, inventory);

      // If the item is out of stock, remove it from the cart and show an alert
      if (outOfStock) {
        itemOutOfStock = true;
        dispatch(
          actions.setAlert({
            title: i18n('General.OutOfStock'),
            description: i18n('General.OutOfStockItem', item.title),
          }),
        );

        shoppingCartApi.removeItem(item.id);
      }

      // If the item is not out of stock, check if any of its variations are out of stock
      item.variations.forEach((variation) => {
        const variationOutOfStock = checkVariationOutOfStock(variation.id, inventory);

        // If the variation is out of stock, remove the parent item from the cart and show an alert
        if (variationOutOfStock) {
          itemOutOfStock = true;
          dispatch(
            actions.setAlert({
              title: i18n('General.OutOfStock'),
              description: i18n('General.OutOfStock.Variation', variation.title, item.title),
            }),
          );
          shoppingCartApi.removeItem(item.id);
        }
      });
    });
    return itemOutOfStock;
  };

  useEffect(() => {
    if (storageLoading) {
      return;
    }
    if (storage.serviceType) {
      return;
    }
    if (tableId) {
      push(`/?v=${venueId}&t=${tableId}`);
    } else if (!tableId && qrOrder) {
      push(`/?v=${venueId}`);
    } else {
      push(`/`);
    }
  }, [storageLoading, storage.serviceType]);

  useEffect(() => {
    if (!shoppingCart.pickupTime || shoppingCart.pickupTime === 'ASAP') {
      return;
    }
    setTime(shoppingCart.pickupTime);
  }, [shoppingCart.pickupTime]);

  useEffect(() => {
    if (shoppingCart.items?.length === 0) {
      setTime('ASAP');
      shoppingCartApi.setPickupTime('ASAP');
    }
  }, [shoppingCart.items?.length]);

  const venueName = useMemo(() => venue?.name ?? '', [venue]);

  useEffect(() => {
    if (!redirectResult) return;
    dispatch(actions.setCartId(new URLSearchParams(search).get('cart')));
    setPayload(JSON.stringify({ details: { redirectResult } }));
  }, [redirectResult]);

  useEffect(() => {
    if (!payload) return;
    updateDetails();
  }, [payload]);

  useEffect(() => {
    if (!swishParams.pp || !swishParams.sessionId) return;
    dispatch(actions.setCartId(new URLSearchParams(search).get('cart')));
    setShowModal(ModalType.PaymentComplete);
  }, [swishParams]);

  useEffect(() => {
    if (!shoppingCart.error || shoppingCart.items.length === 0) return;
    // If we have error events, and the amount of error events is higher than we know about in this components state
    // Then that means that we have received a new error event and should display the error modal.
    if (shoppingCartErrors > 0 && shoppingCartErrors > cartErrorAmount) {
      // We Increment the cartErrorAmount by 1 so that we dont continue to show the error modal for the same error continuously
      dispatch(actions.setCartErrors(cartErrorAmount + 1));
      setShowModal(ModalType.AfterPaymentError);
      logger.warn('shoppingcart recieved error event', {
        cartId,
        shoppingCart,
        errorCode: shoppingCart.error.code,
        errorMessage: shoppingCart.error.message,
        errorContext: shoppingCart.error.context,
      });
    }
  }, [shoppingCart.error]);

  const getSession = async () => {
    setLoading(true);
    try {
      // Keep track of the most recent init session call
      initSessionRef.current += 1;
      const currentInitSession = initSessionRef.current;

      // Run the call
      const { data } = await initSession();

      // If another call has been made since this one, ignore the result
      if (currentInitSession !== initSessionRef.current) {
        return;
      }

      if (data?.initAdyenSession?.success) {
        const result = {
          sessionData: data.initAdyenSession.response?.sessionData,
          id: data.initAdyenSession.response?.id,
        };
        setSessionData(result);
        setError('');

        logger.log('Init payment session success', { result, cartId });

        // Trigger GA event that a payment-session is started.
        ReactGA.event({
          category: 'Payment',
          action: 'payment_started',
        });
      } else {
        setError(data?.initAdyenSession?.error ?? i18n('Session.Error'));

        logger.error('Init payment session failed', { errorMessage: error });

        // Trigger GA event that initializing a payment-session failed.
        ReactGA.event({
          category: 'Payment',
          action: 'payment_initialize_failed',
        });
      }
    } catch (error) {
      setError(i18n('Session.Error'));

      logger.error('Init payment session failed', { errorMessage: error });

      // Trigger GA event that initializing a payment-session failed.
      ReactGA.event({
        category: 'Payment',
        action: 'payment_initialize_failed',
      });
    }
    setLoading(false);
  };

  const updateDetails = async () => {
    setLoading(true);
    try {
      const result = await updatePaymentDetails();
      if (result.data?.updateAdyenPaymentDetails?.success) {
        resultCodeSwitch(
          result.data.updateAdyenPaymentDetails?.response?.resultCode,
          setShowModal,
          api,
        );
      } else {
        logger.warn('ERROR from updatePaymentDetails', {
          errorMessage: result?.data?.updateAdyenPaymentDetails.error,
        });
        resultCodeSwitch('ApiError', setShowModal, api);
      }
    } catch (error) {
      logger.warn('ERROR from updatePaymentDetails', { errorMessage: error });
      resultCodeSwitch('', setShowModal, api);
    }
    setLoading(false);
  };

  // Making the initSession mutation on the cart screen to reduce loading times
  useEffect(() => {
    if (shoppingCart.total <= 0 || !venueOpen.open) {
      return;
    }
    let isSubscribed = true;
    if (isSubscribed && shoppingCart.items.length >= 1) {
      console.log('Getting Session...');
      console.log({ companyCurrency });
      getSession();
    }
    return () => {
      isSubscribed = false;
    };
  }, [shoppingCart.total, venueOpen.open, tips]);

  useEffect(() => {
    let isSubscribed = true;
    if (!shoppingCart.orderId || !isSubscribed) {
      return;
    }
    dispatch(actions.setCartErrors(0));
    setShowModal(ModalType.Hide);
    logger.info('Order succesful', {
      cartId,
      orderId: shoppingCart.orderId,
    });
    // Trigger GA event that a cart is finished.
    ReactGA.event({
      category: 'Cart',
      action: 'cart_finished',
    });
    if (!tableId && user) {
      if (storage.qrOrder) {
        updateStorage({ qrOrder: false });
      }
      push('/');
      push(`/order-history/${shoppingCart.orderId}`);
    } else {
      const createdAt = dayjs().toString();
      updateStorage({
        anonymousOrders: [...storage.anonymousOrders, { id: shoppingCart.orderId, createdAt }],
        qrOrder: false,
      });
      push(`/?v=${venueId}&t=${tableId ?? ''}`);
      push(`/order/${venueId}/order/${shoppingCart.orderId}`);
    }
    return () => {
      isSubscribed = false;
    };
  }, [shoppingCart.orderId]);

  // Do a success vibration when cart is fully paid
  useEffect(() => {
    if (!shoppingCart.isFullyPaid) {
      return;
    }
    api.vibrate('notificationSuccess');
  }, [shoppingCart.isFullyPaid]);

  const onInvoicePayment = () => {
    shoppingCartApi.addClientSideInvoicePayment(shoppingCart.total, tips);
    setShowModal(ModalType.PaymentComplete);
  };

  const onSetTime = (dateTime: string) => {
    shoppingCartApi.setPickupTime(dateTime);
    setTime(dateTime);
  };

  return (
    <>
      <View
        venueName={venueName}
        loading={loading}
        isVenueOpen={venueOpen}
        time={time}
        onSetTime={onSetTime}
        showModal={showModal}
        setShowModal={setShowModal}
        cartId={cartId}
        sessionData={sessionData}
        error={error}
        getSession={getSession}
        setError={setError}
        setPayload={setPayload}
        updateDetails={updateDetails}
        checkAndUpdatePickUpTime={checkAndUpdatePickUpTime}
        tips={tips}
        setTips={setTips}
        onInvoicePayment={onInvoicePayment}
        checkCartItemAvailability={checkCartItemAvailability}
        minimumPickupWaitTime={minimumPickupWaitTime}
      />
    </>
  );
};

export default Cart;
