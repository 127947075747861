import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import Quantity from './Quantity';
import { ReactComponent as NavigateRightIcon } from '../../../Assets/Icons/navigate-right.svg';
import useI18n from '../../../i18n';
import { useCurrency } from 'Hooks';
import { ApolloError } from '@apollo/client/errors';
import Icon from '../../../Components/Icon';

const Wrapper = styled.div`
  position: fixed;
  top: var(--height);
  width: 100%;
  left: 0;
  overflow: hidden;
  margin-top: -${(props) => 80 + props.theme.safeAreaInsetBottom}px;
  display: flex;
  height: 80px;
  padding-bottom: ${(props) =>
    window.hasNativeWrapper ? `0` : props.theme.safeAreaInsetBottom}px;
  z-index: 2;
  box-shadow: 0px -8px 40px 0px rgba(0, 0, 0, 0.24);
  background: #fff;

  @media (min-width: 992px) {
    max-width: 960px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Button = styled.div<{ clicked: boolean; disabled: boolean }>`
  flex: 1;
  display: flex;
  justify-content: space-between;
  height: ${(props) => 80 + props.theme.safeAreaInsetBottom}px;
  padding: 24px 16px 0 16px;
  background: ${({ disabled, theme }) => {
    if (disabled) {
      return '#9E9EA1';
    } else {
      return theme.secondary;
    }
  }};
  opacity: ${({ clicked }) => (clicked ? 0.8 : 1)};
  transition: ${({ clicked }) => (clicked ? '.2s ease-in-out' : '.2s ease-in-out .2s')};
  cursor: pointer;

  @media (min-width: 768px) {
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.9;
    }
  }
`;
const ButtonText = styled.p`
  text-align: left;
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.24px;
  text-transform: uppercase;
  margin-top: 5px;
`;

type Props = {
  /**
   * total
   */
  total: number;
  /**
   * setQuantity
   */
  setQuantity: (quantity: string) => void;
  /**
   * quantity
   */
  quantity: string;
  /**
   * itemInventoryCount
   */
  itemInventoryCount: number | null;
  /**
   * onPress
   */
  onPress: () => void;
  /**
   * error
   */
  error: ApolloError | undefined;
  /**
   * isLoggedIn
   */
  isLoggedIn: boolean;
  /**
   * loading
   */
  loading: boolean;
  /**
   * allRequiredVariationsSelected
   */
  allRequiredVariationsSelected: boolean;
};

/**
 * BottomBar component
 */
const BottomBar = ({
  total,
  setQuantity,
  quantity,
  itemInventoryCount,
  onPress,
  error,
  isLoggedIn,
  loading,
  allRequiredVariationsSelected,
}: Props) => {
  const { i18n } = useI18n();
  const [clicked, setClicked] = useState(false);
  const { formatAmount } = useCurrency();

  const buttonText = useMemo(() => {
    if (error) {
      return 'Item.Button.Error';
    }
    if (loading) {
      return 'General.Loading';
    }
    if (!allRequiredVariationsSelected) {
      return 'Item.Button.RequiredVariations';
    }
    return 'Item.Button.Add';
  }, [error, isLoggedIn, loading, allRequiredVariationsSelected]);

  const disabled = loading || !!error || !allRequiredVariationsSelected;

  const scrollToVariations = () => {
    const element = document.getElementById('groupHeader');

    element?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  };

  return (
    <Wrapper>
      <Quantity
        setQuantity={setQuantity}
        quantity={quantity}
        minValue={1}
        maxValue={itemInventoryCount}
      />
      <Button
        disabled={disabled}
        clicked={clicked}
        onTouchStart={() => setClicked(true)}
        onTouchEnd={() => setClicked(false)}
        onClick={!disabled ? onPress : scrollToVariations}>
        <ButtonText>
          {i18n(buttonText)}{' '}
          {!loading && allRequiredVariationsSelected && !error && formatAmount(total)}
        </ButtonText>
        {!disabled && <Icon icon={<NavigateRightIcon />} color={'#fff'} size={32} />}
      </Button>
    </Wrapper>
  );
};

export default BottomBar;
