import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import GroupHeader from './GroupHeader';
import GroupItem from './GroupItem';
import useI18n from '../../../i18n';
import GroupItemWithImage from './GroupItemWithImage';
import AnimateHeight from 'react-animate-height';
import { useBridgeApi } from '../../../Hooks';

const Wrapper = styled.div`
  flex: 1;
`;

const Items = styled.div``;

export enum GROUP_TYPE {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

export interface ExpandedGroupItem {
  /**
   * id
   */
  id?: string;
  /**
   * name
   */
  name: string;
  /**
   * price
   */
  price?: number;
  /**
   * imageUrl
   */
  imageUrl?: string | undefined;
  /**
   * selected
   */
  selected?: boolean;
  /**
   * isOutOfStock
   */
  isOutOfStock?: boolean;
  /**
   * quantity
   */
  quantity?: number;
  /**
   * inventoryCount
   */
  inventoryCount?: number | null;
}

export interface ExpandedGroup {
  /**
   * type
   */
  type: GROUP_TYPE | null;
  /**
   * title
   */
  title: string;
  /**
   * description
   */
  description: string;
  /**
   * expandedByDefault
   */
  expandedByDefault: boolean;
  /**
   * collapsible
   */
  collapsible: boolean;
  /**
   * onSelect
   */
  onSelect: ((id: string, groupId?: string) => void) | null;
  /**
   * items
   */
  items: ExpandedGroupItem[];
  /**
   * onChangeQuantity
   */
  onChangeQuantity?: (quantity: string, id: string) => void;
}

/**
 * Group component
 */
const Group = ({
  type,
  title,
  description,
  items,
  expandedByDefault,
  collapsible,
  onSelect,
  onChangeQuantity,
}: ExpandedGroup) => {
  const [opened, setOpened] = useState<boolean>(expandedByDefault);
  const { i18n } = useI18n();
  const api = useBridgeApi();
  const withImage = !!items.find((item) => item?.imageUrl);
  const selectedItems = items.filter((item) => item.selected);

  const getHeaderDescription = useCallback(() => {
    let headerTex = '';

    if (type === GROUP_TYPE.MULTIPLE) {
      const firstSelected = selectedItems[0];

      if (selectedItems.length === 0) {
        headerTex = description;
      } else if (selectedItems.length === 1) {
        headerTex = firstSelected.name;
      } else if (selectedItems.length > 1) {
        headerTex = `${firstSelected.name} + ${selectedItems.length - 1} ${i18n('General.More')}`;
      }
    } else {
      headerTex = description;
    }

    return headerTex;
  }, [selectedItems]);

  const headerTex = getHeaderDescription();

  return (
    <Wrapper id="variationgroup">
      <GroupHeader
        onSelect={() => {
          api.vibrate('impactLight');
          setOpened(!opened);
        }}
        title={title}
        description={headerTex}
        opened={!collapsible || opened}
        collapsible={collapsible}
      />
      <AnimateHeight duration={500} height={!collapsible || opened ? 'auto' : 0}>
        <Items>
          {withImage &&
            items.map((item) => (
              <GroupItemWithImage
                id={item.id}
                key={item.id}
                name={item.name}
                onSelect={onSelect}
                selected={item.selected}
                price={item.price}
                imageUrl={item?.imageUrl}
                type={type}
                isOutOfStock={item.isOutOfStock}
                quantity={item.quantity}
                inventoryCount={item.inventoryCount}
                onChangeQuantity={(quantity: string) => {
                  if (onChangeQuantity && item.id) {
                    onChangeQuantity(quantity, item.id);
                  }
                }}
              />
            ))}
          {!withImage &&
            items.map((item) => (
              <GroupItem
                id={item.id}
                key={item.id}
                name={item.name}
                onSelect={onSelect}
                selected={item.selected}
                price={item.price}
                type={type}
                isOutOfStock={item.isOutOfStock}
                quantity={item.quantity}
                inventoryCount={item.inventoryCount}
                onChangeQuantity={(quantity: string) => {
                  if (onChangeQuantity && item.id) {
                    onChangeQuantity(quantity, item.id);
                  }
                }}
              />
            ))}
        </Items>
      </AnimateHeight>
    </Wrapper>
  );
};

export default Group;
