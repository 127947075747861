import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';

import { useDispatch } from 'Hooks';
import * as actions from 'actions';
import {
  venueQuery,
  venueQueryVariables,
  minimumPickupTimeQuery,
  minimumPickupTimeQueryVariables,
} from './__queries__';
import { useShoppingCart } from 'Components/ShoppingCartUniverse';

const VENUE_QUERY = gql`
  query venueQuery($id: ID!) {
    venue: Venue(id: $id) {
      id
      name
      bookingServiceId
      minimumDeliveryFee
      supportEmail
      availabilities {
        id
        dayOfWeek
        timeRange {
          id
          start
          end
        }
      }
      deliveryRates {
        id
        zip
        polygon
        fee {
          id
          price {
            id
            percentageVAT
            amount
          }
        }
        message {
          id
          se
          en
        }
      }
      location {
        id
        name
        lat
        long
      }
    }
  }
`;

const PICKUP_TIME_QUERY = gql`
  query minimumPickupTimeQuery($id: ID!) {
    venue: Venue(id: $id) {
      id
      minimumPickupWaitTime
    }
  }
`;

const VenueManager = () => {
  const params = useParams<{ venue_id?: string }>();
  const venueId = params?.venue_id || '';
  const dispatch = useDispatch();
  const { serviceType } = useShoppingCart();
  const { push } = useHistory();
  const location = useLocation();

  const [getVenueData, { data, loading }] = useLazyQuery<venueQuery, venueQueryVariables>(
    VENUE_QUERY,
    {
      variables: {
        id: venueId,
      },
    },
  );

  const [
    getMinimumPickupWaitTime,
    { data: minimumPickupData, loading: minimumPickupLoading },
  ] = useLazyQuery<minimumPickupTimeQuery, minimumPickupTimeQueryVariables>(PICKUP_TIME_QUERY, {
    variables: {
      id: venueId,
    },
    pollInterval: 30000,
  });

  useEffect(() => {
    if (venueId && serviceType) {
      getVenueData();
      getMinimumPickupWaitTime();
    } else if (!venueId || (!serviceType && location.pathname.includes('order'))) {
      push('/');
    }
  }, [venueId, serviceType]);

  useEffect(() => {
    if (!loading && data) {
      dispatch(actions.setVenue(data.venue));
    }
  }, [data, loading]);

  useEffect(() => {
    if (!minimumPickupLoading && minimumPickupData) {
      dispatch(actions.setMinimumPickupTime(minimumPickupData.venue.minimumPickupWaitTime));
    }
  }, [minimumPickupData, minimumPickupLoading]);
};

export default VenueManager;
