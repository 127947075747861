import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useI18n from 'i18n';
import throttle from 'lodash/throttle';
// @ts-ignore
import smoothscroll from 'smoothscroll-polyfill';
import Entry from './components/Entry';
import SubCategoriesMenu from './components/SubCategoriesMenu';
import { getCategoryInfo_category } from './__queries__';
import getImageUrlWithSize from 'utils/getImageUrlWithSize';
import { checkOutOfStock } from '../../utils/inventory';
import { useSelector } from '../../Hooks';
import { CategoryItem, SubCategoryWithData } from './index';
import { DEVICE_SIZES, getDeviceSize } from '../../utils/deviceSize';
import SignInBottomModal from '../../Components/SignInBottomModal';

const Wrapper = styled.div<{ largeBottomOffset: boolean }>`
  padding-bottom: ${({ largeBottomOffset }) =>
    largeBottomOffset ? '700' : window.hasNativeWrapper ? 88 : 80}px;
  min-height: var(--height);
  background: #f8f8fb;
  animation: fade-in 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
`;
const Header = styled.div<{ image: string }>`
  height: 232px;
  padding: 96px 56px;
  position: relative;
  background: ${({ image, theme }) => (image ? `url(${image})` : theme.main)};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 768px) {
    height: 218px;
  }
`;
const Title = styled.h2<{ smallTitle: boolean }>`
  font-size: ${({ smallTitle }) => (smallTitle ? `26px` : `32px`)};
  line-height: 36px;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.menuTextColor};
  position: relative;
  text-align: center;
`;
const SubTitle = styled.h3`
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.24px;
  line-height: 20px;
  color: #e0e0e3;
  position: relative;
  text-align: center;
`;
const InnerWrapper = styled.div`
  padding: 8px 8px 1px;
  height: 100%;
  overflow: auto;

  @media (min-width: 768px) {
    padding: 16px 16px 1px;
  }
`;

const SubCategory = styled.div`
  padding: 16px 0 8px 0;

  @media (min-width: 768px) {
    padding: 0 0 8px 0;
  }
`;

const SubCategoryTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  color: #000000;
  margin: 0 0 12px 16px;

  @media (min-width: 992px) {
    margin: 0 auto 12px auto;
    max-width: 960px;
  }
`;

const CategoryItems = styled.div`
  @media (min-width: 992px) {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
    margin: 0 auto;
    max-width: 960px;
  }
`;

type Props = {
  categoryId: string;
  activeSubCategoryId: string | null;
  categoryInfo: getCategoryInfo_category;
  items: CategoryItem[];
  setActiveSubCategoryId: React.Dispatch<React.SetStateAction<string | null>>;
  subCategoriesWithData: SubCategoryWithData[] | undefined;
};
const MenuView = ({
  categoryId,
  categoryInfo,
  items,
  setActiveSubCategoryId,
  activeSubCategoryId,
  subCategoriesWithData,
}: Props) => {
  const { i18n } = useI18n();
  const { nameLang, descriptionLang, image } = categoryInfo;
  const smallTitle = i18n(nameLang).length > 20;
  const isInstant = items.some((item) => item.instant);
  const inventory = useSelector((state) => state.inventory ?? []);
  const hasSubCategories = Boolean(subCategoriesWithData && subCategoriesWithData?.length > 0);
  const subCategoriesRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [clickedId, setClickedId] = useState<string | null>(null);
  const clickedIdRef = useRef<string | null>(null);
  const navbarHeight = window.headerHeight;
  const extraOffset = 24;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const deviceSize = getDeviceSize();

  useEffect(() => {
    clickedIdRef.current = clickedId;
  }, [clickedId]);

  useEffect(() => {
    smoothscroll.polyfill();
    containerRef.current = document.querySelector(`.category-scroll`);
  }, []);

  useEffect(() => {
    if (!subCategoriesWithData) {
      return;
    }
    const container = containerRef.current;
    container?.addEventListener('scroll', handleScroll, true);

    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, [subCategoriesWithData]);

  const handleScroll = throttle(() => {
    const container = containerRef.current;
    const lastScrollY = container?.scrollTop;

    if (!lastScrollY || !subCategoriesWithData) {
      return;
    }

    if (clickedIdRef.current) {
      const section = subCategoriesRefs.current[clickedIdRef.current];
      const location = section?.offsetTop;
      if (location) {
        const destination = location - navbarHeight;
        if (lastScrollY === destination) {
          setClickedId(null);
        }
      }
    }

    for (let i = 0; i < subCategoriesWithData.length; i++) {
      const currentId = subCategoriesWithData[i].id;
      const sectionElement = subCategoriesRefs.current[currentId];
      const sectionOffset = sectionElement?.offsetTop;
      if (!sectionElement || !sectionOffset) {
        return;
      }
      const offset = sectionOffset + sectionElement.clientHeight - navbarHeight - extraOffset;

      if (lastScrollY <= offset) {
        setActiveSubCategoryId(currentId);
        return;
      }
    }
  }, 20);

  const scrollToSubCategory = (id: string) => {
    const section = subCategoriesRefs.current[id];
    const location = section?.offsetTop;
    const container = containerRef?.current;

    if (!location || !container) {
      return;
    }

    const destination = location - navbarHeight;

    container.scrollTo({
      top: destination,
      left: 0,
      behavior: 'smooth',
    });
  };

  const handleClickedId = (id: string) => {
    setClickedId(id);
    scrollToSubCategory(id);
  };

  const subCategoryItemsExist =
    subCategoriesWithData &&
    subCategoriesWithData.map((category) => category.items).flat()?.length > 0;

  const headerImageUrl = image?.file?.url;
  const headerImage =
    deviceSize === DEVICE_SIZES.SMALL
      ? getImageUrlWithSize(headerImageUrl, window.innerWidth * 2, 464)
      : headerImageUrl;

  return (
    <Wrapper largeBottomOffset={hasSubCategories} ref={wrapperRef}>
      <Header image={headerImage}>
        <Title smallTitle={smallTitle}>{i18n(nameLang)}</Title>
        <SubTitle>{i18n(descriptionLang)}</SubTitle>
      </Header>
      <InnerWrapper>
        <CategoryItems>
          {!hasSubCategories &&
            items.map((entry) => {
              return (
                <Entry
                  key={entry.id}
                  categoryId={categoryId}
                  {...entry}
                  instant={isInstant}
                  outOfStock={checkOutOfStock(entry.item.id, inventory)}
                />
              );
            })}
        </CategoryItems>
        {hasSubCategories &&
          subCategoriesWithData?.map((category) => {
            if (category.items.length === 0) {
              return null;
            }
            return (
              <SubCategory
                key={category.id}
                ref={(el) => (subCategoriesRefs.current[category.id] = el)}>
                <SubCategoryTitle>{i18n(category.nameLang)}</SubCategoryTitle>
                <CategoryItems>
                  {category.items.map((entry) => {
                    return (
                      <Entry
                        key={entry.id}
                        categoryId={categoryId}
                        {...entry}
                        instant={category.isInstant}
                        outOfStock={checkOutOfStock(entry.item.id, inventory)}
                      />
                    );
                  })}
                </CategoryItems>
              </SubCategory>
            );
          })}
      </InnerWrapper>
      {subCategoriesWithData && subCategoryItemsExist && (
        <SubCategoriesMenu
          subCategories={subCategoriesWithData}
          onClick={handleClickedId}
          selectedId={clickedId || activeSubCategoryId}
        />
      )}
    </Wrapper>
  );
};

export default MenuView;
