import React from 'react';
import styled from 'styled-components';
import useI18n from '../../../i18n';
import Group, { GROUP_TYPE, ExpandedGroup } from '../Components/Group';
import { GetItem_item_upsales } from '../__queries__';
import useEntryFinder from 'Hooks/useEntryFinder';
import { checkOutOfStock } from 'utils/inventory';
import { useSelector } from 'Hooks';
import { UpsalesQuantity } from '../index';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Inner = styled.div`
  flex: 1;
  padding: ${() => window.headerHeight}px 24px 100px 24px;

  @media (min-width: 992px) {
    max-width: 960px;
    margin: 0 auto;
    width: 100%;
  }

  @media (min-width: 1400px) {
    padding: ${() => window.headerHeight}px 0 100px 0;
  }
`;

const Navigation = styled.div`
  margin: 0 -24px;
  border-bottom: solid 1px #ffffff;
  background: rgb(245, 245, 248);
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;

  @media (min-width: 992px) {
    margin: 0;
  }
`;

const Dot = styled.div<{ active?: boolean }>`
  background: ${({ active }) => (active ? 'rgb(33, 33, 36)' : 'rgb(189, 189, 192)')};
  height: 8px;
  width: 8px;
  border-radius: 4px;
  margin: 0 16px 0 0;

  :last-child {
    margin: 0;
  }
`;

const Button = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px 16px;
  background: ${({ theme }) => theme.secondary};
  border-radius: 0;
  height: 80px;
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.24px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;

  @media (min-width: 768px) {
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.9;
    }
  }

  @media (min-width: 992px) {
    max-width: 960px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

type Props = {
  /**
   * groups
   */
  groups: GetItem_item_upsales[];
  /**
   * upsalesQuantity
   */
  upsalesQuantity: UpsalesQuantity[];
  /**
   * onChangeQuantity
   */
  onChangeQuantity: (groupId: string, id: string, quantity: string) => void;
  /**
   * step
   */
  step: number;
  /**
   * setStep
   */
  setStep: React.Dispatch<React.SetStateAction<number>>;
  /**
   * onSubmit
   */
  onSubmit: () => void;
};

/**
 * Upsales scene
 */
const Upsales = ({
  groups,
  upsalesQuantity,
  step,
  onChangeQuantity,
  setStep,
  onSubmit,
}: Props) => {
  const { i18n } = useI18n();
  const findEntryFromItem = useEntryFinder();
  const inventory = useSelector((state) => state.inventory);

  const onNext = () => {
    if (step < groups.length - 1) {
      setStep(step + 1);
    }
  };

  const getButtonHandler = () => {
    if (step === groups.length - 1) {
      return onSubmit;
    } else if (step < groups.length) {
      return onNext;
    }
    return undefined;
  };

  const group = groups[step];
  const buttonHandler = getButtonHandler();

  const findItem = (groupId: string, id: string) => {
    const currentGroup = upsalesQuantity.find((g) => g.id === groupId);
    return currentGroup?.items.find((i) => i.id === id);
  };

  const getQuantity = (groupId: string, id: string) => {
    const item = findItem(groupId, id);

    return item?.quantity || 0;
  };

  const getInventoryCount = (groupId: string, id: string) => {
    const item = findItem(groupId, id);

    return item?.inventoryCount ? item?.inventoryCount : null;
  };

  const currentGroup: ExpandedGroup = {
    type: GROUP_TYPE.MULTIPLE,
    title: i18n(group.name),
    description: i18n('Item.ChooseOption'),
    expandedByDefault: true,
    onSelect: null,
    onChangeQuantity: (quantity: string, id: string) => {
      onChangeQuantity(group.id, id, quantity);
    },
    items: group.items
      .filter((item) => findEntryFromItem(item.id))
      .map((i) => ({
        id: i.id,
        name: i18n(i.titleLang),
        price: i.defaultPrice,
        imageUrl: i?.image?.file?.url,
        isOutOfStock: checkOutOfStock(i.id, inventory),
        quantity: getQuantity(group.id, i.id),
        inventoryCount: getInventoryCount(group.id, i.id),
      })),
    collapsible: false,
  };

  return (
    <Wrapper>
      <Inner>
        <Navigation>
          {groups.map((g, index: number) => (
            <Dot key={g.id} active={index === step} />
          ))}
        </Navigation>
        <Group
          type={currentGroup.type}
          title={currentGroup.title}
          description={currentGroup.description}
          items={currentGroup.items}
          expandedByDefault={currentGroup.expandedByDefault}
          onSelect={currentGroup.onSelect}
          collapsible={currentGroup.collapsible}
          onChangeQuantity={(quantity: string, id: string) => {
            if (currentGroup.onChangeQuantity) {
              currentGroup.onChangeQuantity(quantity, id);
            }
          }}
        />
      </Inner>
      <Button onClick={buttonHandler}>{i18n('Item.Next')}</Button>
    </Wrapper>
  );
};

export default Upsales;
